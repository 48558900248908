<template>
  <v-container>
    <Patcher
      :itemValue="itemData.mediaUuid"
      itemName="mediaUuid"
      itemLabel="Foto"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement=""
      inputType="media"
      :previewValue="itemData.mediaUuid"
      @emit="itemData.mediaUuid = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.personName"
      itemName="personName"
      itemLabel="Name"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="input"
      inputType="text"
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.personName"
      @emit="itemData.personName = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.personAge"
      itemName="personAge"
      itemLabel="Alter"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="input"
      inputType="number"
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.personAge"
      @emit="itemData.personAge = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.headline"
      itemName="headline"
      itemLabel="Überschrift"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="input"
      inputType="text"
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.headline"
      @emit="itemData.headline = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.personQuote"
      itemName="personQuote"
      itemLabel="Zitat"
      :hideLabel="false"
      :route="patchRoute + itemData.id"
      inputElement="textarea-md"
      inputType=""
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.personQuote"
      @emit="itemData.personQuote = $event.value"
    ></Patcher>
  </v-container>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "Testimonial",

  components: {
    Patcher,
  },

  props: ["itemData"],

  data() {
    return {
      filePatcherKey: 0,
      patchRoute: "testimonials/",
      fetchingData: false,
    };
  },

  mixins: [apiRequest, helpers],
};
</script>
