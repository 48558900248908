<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <DataTable
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
          }"
          :search="search"
          :preloader="fetchingData"
        />
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/tech/pages"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>

    <v-dialog
      v-model="detailDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      :key="componentKey"
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>Testimonial</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="detailDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <Testimonial :itemData="itemData" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fixed
          dark
          fab
          bottom
          right
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="createNew"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Neues Testimonial anlegen</span>
    </v-tooltip>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import Testimonial from "@/components/pages/testimonials/Testimonial.vue";
import DataTable from "@/components/ui/DataTable.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
export default {
  created: function () {
    this.getData(); // call it immediatly
  },

  name: "Testimonials",

  components: {
    PageHeader,
    Testimonial,
    DataTable,
    Subnavigation,
  },

  data() {
    return {
      componentKey: 0,
      detailDialog: false,
      itemData: [],
      itemIndex: null,
      pageTitle: "Testimonials",
      pageDescription:
        "Hier werden Testimonials verwaltet. Testimonials können einem Kunden zugewiesen werden.",
      search: "",
      headers: [],
      items: [],
      fetchingData: false,
    };
  },

  methods: {
    async createNew() {
      /* create new entry */
      let response = await this.postRequest("testimonials");

      if (response.status === 200) {
        this.items.push(response.data.data[0]);

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde erfolgreich angelegt.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest("testimonials");
      this.fetchingData = false;

      if (response) {
        this.headers = [
          { text: "ID", value: "id", width: "50px" },
          { text: "Aktiv", value: "isActive", width: "50px" },
          { text: "Bild", value: "mediaUuid", width: "76px" },
          { text: "Name", value: "personName" },
          { text: "Quote", value: "personQuote" },
          { text: "", value: "actions", sortable: false, width: "50px" },
        ];

        this.items = response.data.data;
      }
    },

    /* Emitted Actions:
    Hier werden die aus der <DataTable></DataTable> Komponente 'emittete' Werte verarbeitet. 
    Die Komponente übermittelt die Button-Klicks ... editItem etc.*/

    itemAction(action, item, index) {
      if (action === "edit") {
        this.componentKey += 1;
        this.itemData = item;
        this.itemIndex = index;
        this.detailDialog = true;
      }
    },

    emittedAction(value) {
      if (value.action === "editItem") {
        this.componentKey += 1;
        this.itemData = value.item;
        this.itemIndex = value.index;
        this.detailDialog = true;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
